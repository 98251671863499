import { render, staticRenderFns } from "./NovaPalestra.vue?vue&type=template&id=a2e93bc0&scoped=true"
import script from "./NovaPalestra.vue?vue&type=script&lang=js"
export * from "./NovaPalestra.vue?vue&type=script&lang=js"
import style0 from "./NovaPalestra.vue?vue&type=style&index=0&id=a2e93bc0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2e93bc0",
  null
  
)

export default component.exports