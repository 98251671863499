<template>
  <v-container fluid>
    <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
      <v-card>
        <v-card-title class="pa-4 accent">
          <v-btn :to="{ path: '/palestras' }" small exact fab text class="mr-2">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-icon color="primary" left>mdi-account-group-outline</v-icon>
          Adicionar Palestra
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="">
          <!-- <v-tabs class="" v-model="tab">
            <v-tab class="caption font-weight-bold ">
              Informações
            </v-tab>
            <v-tab class="caption font-weight-bold ">
              Midias
            </v-tab>
          </v-tabs> -->

          <h3 class="secondary--text text-h5 font-weight-medium my-6">
            1.Dados da Palestra
          </h3>
          <v-row>
            <v-col cols="12" md="3">
              <v-card
                flat
                class="fill-height d-flex align-center justify-center"
              >
                <v-card flat v-if="destaque">
                  <v-img :src="destaque" contain></v-img>
                </v-card>
                <div v-else class="fill-height" style="width: 100%">
                  <vue-dropzone
                    ref="myVueDropzone"
                    id="dropzone"
                    :class="{ hoverFile: hoverFile }"
                    :use-custom-dropzone-options="true"
                    :options="dropzoneOptions"
                    @vdropzone-file-added="fileAdded"
                    @vdropzone-drag-enter="hoverFile = true"
                    @vdropzone-drag-over="hoverFile = true"
                    @vdropzone-drag-leave="hoverFile = false"
                    :useCustomSlot="true"
                    :include-styling="false"
                  >
                    <div class="text-center">
                      <h3 class="text-subtitle-1 font-weight-bold">
                        CAPA PALESTRA
                      </h3>
                      <v-icon size="80" color="info"> mdi-image-plus </v-icon>
                      <h3 class="text-subtitle-1">Arrastar e soltar</h3>
                      <div class="text-caption">Clique para selecionar</div>
                    </div>
                  </vue-dropzone>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="9">
              <v-row dense class="pl-4">
                <!-- Descrição -->
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="palestra.descricao"
                    label="Descrição"
                    required
                    :rules="formRules"
                    dense
                  ></v-text-field>
                </v-col>
                <!-- Descrição Detalhada -->
                <v-col cols="12" md="12">
                  <v-textarea
                    v-model="palestra.descricao_detalhada"
                    label="Descrição"
                    dense
                  ></v-textarea>
                </v-col>
                <!-- Data Palestra -->
                <v-col cols="12" md="6">
                  <v-btn-toggle
                    mandatory
                    color="xbColor"
                    v-model="palestra.data"
                  >
                    <v-btn value="2024-03-01" height="50px"> 01 </v-btn>
                    <v-btn value="2024-03-02" height="50px"> 02 </v-btn>
                    <v-btn value="2024-03-03" height="50px"> 03 </v-btn>
                  </v-btn-toggle>
                </v-col>

                <!-- Exibir Palestra no App -->
                <v-col cols="12" md="4">
                  <v-card
                    height="40px"
                    flat
                    class="pa-3 d-flex justify-space-between align-center"
                  >
                    <v-subheader
                      style="height: auto !important"
                      class="ma-0 pa-0 body-1"
                    >
                      <span class="pl-1" v-if="palestra.exibir_app === true">
                        Exibir
                      </span>
                      <span class="pl-1" v-else> Não exibir </span>
                      <span class="ml-2">no app</span>
                    </v-subheader>
                    <v-switch
                      :false-value="false"
                      :true-value="true"
                      color="xbColor"
                      v-model="palestra.exibir_app"
                    ></v-switch>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="mt-6"></v-divider>
          <h3 class="secondary--text text-h5 font-weight-medium my-6">
            2.Dados do Palestrante
          </h3>
          <v-row dense>
            <v-col cols="12" md="2">
              <v-card
                flat
                class="fill-height d-flex align-center justify-center"
              >
                <v-card flat v-if="avatar">
                  <v-img :src="avatar" contain></v-img>
                </v-card>
                <div v-else class="fill-height" style="width: 100%">
                  <vue-dropzone
                    ref="myVueDropzone"
                    id="dropzone"
                    :class="{ hoverFile: hoverFile }"
                    :use-custom-dropzone-options="true"
                    :options="dropzoneOptions"
                    @vdropzone-file-added="fileAdded2"
                    @vdropzone-drag-enter="hoverFile2 = true"
                    @vdropzone-drag-over="hoverFile2 = true"
                    @vdropzone-drag-leave="hoverFile2 = false"
                    :useCustomSlot="true"
                    :include-styling="false"
                  >
                    <div class="text-center">
                      <h3 class="text-subtitle-1 font-weight-bold">
                        Imagem Palestrante
                      </h3>
                      <v-icon size="80" color="info"> mdi-image-plus </v-icon>
                      <h3 class="text-subtitle-1">Arrastar e soltar</h3>
                      <div class="text-caption">Clique para selecionar</div>
                    </div>
                  </vue-dropzone>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="10">
              <v-row class="pl-4">
                <!-- Nome Palestrante -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="palestra.palestrante"
                    label="Nome"
                    dense
                  ></v-text-field>
                </v-col>
                <!-- Ramo Palestrante -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="palestra.palestrante_ramoatividade"
                    label="Ramo Atividade"
                    dense
                  ></v-text-field>
                </v-col>
                <!-- Instagram Palestrante -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="palestra.palestrante_instagram"
                    label="Instagram"
                    dense
                  ></v-text-field>
                </v-col>
                <!-- Facebook Palestrante -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="palestra.palestrante_facebook"
                    label="Facebook"
                    dense
                  ></v-text-field>
                </v-col>
                <!-- email Palestrante -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="palestra.palestrante_email"
                    label="Email"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Site Palestrante -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="palestra.palestrante_site"
                    label="Site"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Youtube Palestrante -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="palestra.palestrante_canalyoutube"
                    label="Canal Youtube"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Links Palestrante -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="palestra.palestrante_links"
                    label="Links"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Palestrante Curriculo -->
                <v-col cols="12" md="12">
                  <v-textarea
                    v-model="palestra.palestrante_curriculum"
                    label="Minibio"
                    dense
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-3">
          <v-btn text :to="{ path: '/palestras' }" exact color="primary">
            <v-icon class="mr-2" left>mdi-arrow-left</v-icon>
            Voltar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            @click="createPalestra"
            :disabled="!validForm"
            color="xbColor"
            class="white--text"
          >
            <v-icon left>mdi-plus</v-icon>
            Adicionar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import { postPalestra } from "@/api/admin/palestras.js";
import { inputRequired } from "@/plugins/utils.js";

export default {
  name: "NovaPalestra",

  data() {
    return {
      palestra: {
        exibir_app: false,
      },
      destaque: null,
      destaqueUpload: null,
      filesAdicionado: [],
      files: [],
      avatar: null,
      avatarUpload: null,
      loading: false,
      menuHora: false,
      menuHora2: false,
      hoverFile: false,
      hoverFile2: false,
      validForm: true,
      time: "12:00",

      time2: "12:00",
      formRules: [inputRequired],
      tab: 0,
    };
  },

  components: {
    vueDropzone: vue2Dropzone,
  },

  computed: {
    dropzoneOptions() {
      return {
        url: "/no-url",
        createfileThumbnails: false,
        acceptedFiles: ".png,.jpg,.jpeg",
        maxFilesize: 2,
        autoProcessQueue: false,
      };
    },
  },

  watch: {},

  methods: {
    fileAdded(file) {
      this.destaqueUpload = file;
      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.destaque = event.target.result;
      };
    },
    fileAdded2(file) {
      this.avatarUpload = file;
      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.avatar = event.target.result;
      };
    },

    createPalestra() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const palestra = new FormData();

        if (this.destaqueUpload) {
          palestra.append(
            "img_destaque",
            this.destaqueUpload,
            this.destaqueUpload.name
          );
        }
        if (this.avatarUpload) {
          palestra.append(
            "palestrante_avatar",
            this.avatarUpload,
            this.avatarUpload.name
          );
        }
        if (this.palestra.files) {
          for (var i = 0; i < this.palestra.files.length; i++) {
            let file = this.palestra.files[i];

            palestra.append("files[" + i + "]", file);
          }
        }
        let palestra_2 = {};
        palestra_2.descricao = this.palestra.descricao;
        palestra_2.descricao_detalhada = this.palestra.descricao_detalhada;
        palestra_2.data = this.palestra.data;
        palestra_2.exibir_app = this.palestra.exibir_app;
        palestra_2.palestrante = this.palestra.palestrante;
        palestra_2.palestrante_avatar = this.palestra.palestrante_avatar;
        palestra_2.palestrante_curriculum =
          this.palestra.palestrante_curriculum;
        palestra_2.palestrante_instagram = this.palestra.palestrante_instagram;
        palestra_2.palestrante_facebook = this.palestra.palestrante_facebook;
        palestra_2.palestrante_email = this.palestra.palestrante_email;
        palestra_2.palestrante_ramoatividade =
          this.palestra.palestrante_ramoatividade;
        palestra_2.palestrante_site = this.palestra.palestrante_site;
        palestra_2.palestrante_canalyoutube =
          this.palestra.palestrante_canalyoutube;
        palestra_2.palestrante_links = this.palestra.palestrante_links;

        for (let key in palestra_2) {
          if (
            palestra_2[key] !== null &&
            palestra_2[key] !== undefined &&
            palestra_2[key] !== ""
          ) {
            palestra.append(key, palestra_2[key]);
          }
        }

        postPalestra(palestra)
          .then((response) => {
            if (response.status === 201) {
              this.$toast.success("Palestra cadastrada com sucesso");
              this.loading = false;
              this.$router.push({ path: "/palestras" });
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.hoverFile {
  border-color: #2196f3 !important;
  color: #2196f3 !important;
}
#dropzone {
  border: 2px dashed #dddddd;
  padding: 16px;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  &:hover {
    border-color: #2196f3;
    color: #2196f3;
  }

  ::v-deep .dz-preview {
    display: none !important;
  }
}
</style>
